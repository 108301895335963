import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map, catchError, throwError } from 'rxjs';
import { RoleVariables, SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class RoleManagementService {
  constructor(private http: HttpClient, private sharedservice: SharedService) {}

  getRolesList(payload: RoleVariables) {
    let url =
      environment.backendurl + `/api/acls/rbac-list/` + payload.accountid;
    if (
      environment.sso &&
      this.sharedservice
        .getUserDetails()
        ?.userDetails?.account?.account_type?.toUpperCase() === 'ROOT'
    ) {
      url = url.replace('rbac-list', 'rbac-admin-list');
    }
    let params = new HttpParams();
    if (payload.page) {
      params = params.set('page', payload.page);
    }
    if (payload.size) {
      params = params.set('size', payload.size);
    }
    if (payload.startdate) {
      params = params.set('start_date', payload.startdate);
    }
    if (payload.enddate) {
      params = params.set('end_date', payload.enddate);
    }
    if (payload.name) {
      params = params.set('role_name', payload.name);
    }

    return this.http.get(url, { params }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  createRole(payload: any) {
    const url = environment.backendurl + `/api/acls/rbac`;

    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  viewRoleDtls(id: string) {
    let url = environment.backendurl + `/api/acls/rbac/` + id;
    if (
      environment.sso &&
      this.sharedservice
        .getUserDetails()
        ?.userDetails?.account?.account_type?.toUpperCase() === 'ROOT'
    ) {
      url = url.replace('rbac', 'rbac-admin');
    }
    return this.http.get(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  updateRoleDtls(id: string, payload: any) {
    const url = environment.backendurl + `/api/acls/rbac/` + id;

    return this.http.put(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  deleteRole(id: string) {
    const url = environment.backendurl + `/api/acls/rbac/` + id;

    return this.http.delete(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }
}
