<app-alert></app-alert>
<span class="loaderpostion">
  <div class="reset_loading">
    <div class="loader"></div>
  </div>
</span>

<div class="d-flex align-items-center justify-content-center vh-100 mt-4">
  <h1>Please wait while we are validating...</h1>
</div>
