<!-- ========== MAIN CONTENT ========== -->
<div id="cst_rpwd_screen" role="main" class="main pt-0">
  <!-- Content -->
  <div class="container-fluid px-3">
    <!-- Row Start -->
    <div class="row">
      <!-- Reset Password -->
      <div
        class="d-flex justify-content-center align-items-center min-vh-lg-100 p-lg-4 p-sm-0"
      >
        <div class="card w-40 py-5 p-6">
          <div class="mb-2 text-center">
            <!-- Right Side WBNLU Logo -->
            <img
              class="img-fluid"
              id="login_main_logo"
              [src]="whiteLabeledData?.logo"
              alt="Company Logo"
            />
            <!-- END Right Side WBNLU Logo -->
          </div>
          <div class="cst_rpwd_sec">
            <ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>

            <!-- Skeleton Template -->
            <ng-template #skeletonTemp>
              <!-- <ngx-skeleton-loader
                class="skeleton_loader"
                *ngFor="let item of skeletonData"
                [theme]="item"
              ></ngx-skeleton-loader> -->
              <div class="reset_loading">
                <div class="loader"></div>
              </div>
            </ng-template>
            <!-- END Skeleton Template -->

            <!-- SHOW Reset Password FORM -->
            <ng-template #resetPwd>
              <div class="mb-3">
                <h5 class="mb-1">Create New Password</h5>
                <p class="text-dark mb-2">
                  your new password must be different from your previous used
                  password
                </p>
              </div>
              <!-- Reset Password form start -->
              <form
                [formGroup]="resetForm"
                id="cst_reset_form"
                (ngSubmit)="reset(resetForm)"
              >
                <!-- New Password -->
                <label class="input-label" for="resetPassword"
                  >New Password
                  <span class="text-danger" matTooltip="Password is mandatory"
                    >*</span
                  ></label
                >
                <div class="input-group input-group-merge">
                  <div class="input-group-prepend input-group-text">
                    <em class="bi bi-envelope-fill gd_icon"></em>
                  </div>
                  <input
                    formControlName="newPassword"
                    [type]="showPassword ? 'text' : 'password'"
                    class="form-control form-control-lg"
                    placeholder="Enter New Password"
                    aria-label="password"
                    name="newPassword"
                    id="resetPassword"
                    tabindex="0"
                    autocomplete="off"
                  />
                  <div class="input-group-append input-group-text changePassTarget">
                    <em
                      class="changePassIcon bi"
                      [ngClass]="{
                        'bi-eye-fill': !showPassword,
                        'bi-eye-slash-fill': showPassword
                      }"
                      (click)="showPassword = !showPassword"
                    >
                    </em>
                  </div>
                </div>
                <!-- Reset New Password error -->
                <div class="invalid-feedback">
                  <div
                    *ngIf="
                      rpwdFC['newPassword'].touched ||
                      rpwdFC['newPassword'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="rpwdFC['newPassword'].value === ''; else fnInvalid"
                    >
                      Please enter valid New Password.
                    </ng-container>

                    <ng-template #fnInvalid>
                      <span *ngIf="rpwdFC['newPassword'].value.length < 8">
                        Password length should be minimum 8 characters
                      </span>
                      <div
                        *ngIf="
                          rpwdFC['newPassword'].value.length >= 8 &&
                          rpwdFC['newPassword'].errors
                        "
                      >
                        Password should contain atleast
                        <span
                          *ngIf="rpwdFC['newPassword'].hasError('hasSmallCase')"
                          >1 Lowercase</span
                        >
                        <span
                          *ngIf="
                            rpwdFC['newPassword'].hasError('hasCapitalCase')
                          "
                          >1 Uppercase</span
                        >
                        <span
                          *ngIf="rpwdFC['newPassword'].hasError('hasNumber')"
                          >, 1 Numeric value</span
                        >
                        <span
                          *ngIf="
                            rpwdFC['newPassword'].hasError(
                              'hasSpecialCharacters'
                            )
                          "
                          >, 1 Special Character</span
                        >.
                      </div>
                    </ng-template>
                  </div>
                </div>
                <!-- End Reset New Password error -->
                <!-- END New Password -->

                <!-- Confirm Password -->
                <label class="input-label" for="resetCFPassword"
                  >Confirm Password
                  <span class="text-danger" matTooltip="Password is mandatory"
                    >*</span
                  ></label
                >
                <div class="input-group input-group-merge">
                  <div class="input-group-prepend input-group-text">
                    <em class="bi bi-lock-fill gd_icon"></em>
                  </div>
                  <input
                    formControlName="confirmPassword"
                    [type]="showCFPassword ? 'text' : 'password'"
                    class="form-control form-control-lg"
                    placeholder="confirm password"
                    aria-label="confirm password"
                    name="confirmPassword"
                    id="resetCFPassword"
                    data-msg="Your confirm password is invalid. Please try again."
                    tabindex="0"
                  />

                  <div class="input-group-append input-group-text changePassTarget">
                    <em
                      class="changePassIcon bi"
                      [ngClass]="{
                        'bi-eye-fill': !showCFPassword,
                        'bi-eye-slash-fill': showCFPassword
                      }"
                      (click)="showCFPassword = !showCFPassword"
                    >
                    </em>
                  </div>
                </div>
                <!-- Reset Confirm password error -->
                <div
                  id="resetCFPassword-error"
                  class="invalid-feedback"
                >
                  <div
                    *ngIf="
                      rpwdFC['confirmPassword'].touched ||
                      rpwdFC['confirmPassword'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        rpwdFC['confirmPassword'].value === '' ||
                          rpwdFC['confirmPassword'].value === null;
                        else fnInvalid
                      "
                    >
                      Please enter valid Confirm Password.
                    </ng-container>

                    <ng-template #fnInvalid>
                      <span
                        *ngIf="
                          rpwdFC['confirmPassword'].value !==
                          rpwdFC['newPassword'].value
                        "
                      >
                        Password and Confirm Password does not match.
                      </span>
                    </ng-template>
                  </div>
                </div>
                <!-- End Reset Confirm password error -->
                <!-- END Confirm Password -->

                <!-- Password Requirements -->
                <div
                  class="row form-group"
                  *ngIf="
                    rpwdFC['newPassword'].value !== '' &&
                    rpwdFC['newPassword']?.dirty &&
                    rpwdFC['newPassword'].errors
                  "
                >
                  <div class="col-lg-12 reset_pwd_requirements">
                    <ul class="list-unstyled font-size-sm">
                      <li
                        [ngClass]="
                          rpwdFC['newPassword'].value.length < 8
                            ? 'text-muted'
                            : 'text-success'
                        "
                      >
                        <em class="bi-check-circle-fill"></em>
                        8 Characters Minimum
                      </li>
                      <li
                        [ngClass]="
                          rpwdFC['newPassword'].hasError('hasSmallCase')
                            ? 'text-muted'
                            : 'text-success'
                        "
                      >
                        <em class="bi-check-circle-fill"></em>
                        At least one lowercase character
                      </li>
                      <li
                        [ngClass]="
                          rpwdFC['newPassword'].hasError('hasCapitalCase')
                            ? 'text-muted'
                            : 'text-success'
                        "
                      >
                        <em class="bi-check-circle-fill"></em>
                        At least one uppercase character
                      </li>
                      <li
                        [ngClass]="
                          rpwdFC['newPassword'].hasError('hasNumber')
                            ? 'text-muted'
                            : 'text-success'
                        "
                      >
                        <em class="bi-check-circle-fill"></em>
                        At least one number
                      </li>
                      <li
                        [ngClass]="
                          rpwdFC['newPassword'].hasError('hasSpecialCharacters')
                            ? 'text-muted'
                            : 'text-success'
                        "
                      >
                        <em class="bi-check-circle-fill"></em>
                        At least one special character
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- END Password Requirements -->

                <!-- Reset Password button -->
                <button
                  class="btn btn-lg btn-block btn-primary mt-4"
                  tabindex="3"
                  type="submit"
                  [ngStyle]="{
                    cursor:
                      resetForm.status !== 'VALID' ? 'not-allowed' : 'pointer'
                  }"
                  value="Login"
                  [disabled]="resetForm.invalid"
                >
                  Reset Password
                </button>
                <!-- End Reset Password button -->

                <!-- Go back to Log in Button -->
                <a
                  class="btn btn-block mt-2 text-primary"
                  routerLink="/login"
                  tabindex="4"
                >
                  <i class="bi bi-chevron-left"></i> Back to Login
                </a>
                <!-- END Go back to Log in Button -->
              </form>
              <!-- Reset Password form END -->
            </ng-template>
            <!-- End SHOW Reset Password FORM -->

            <!-- Reset Password Success Msg -->
            <ng-template #resetSuccess>
              <div class="reset_success text-center">
                <img
                  src="assets/img/success.svg"
                  alt="Password Reset Success"
                />
                <h5 id="frgt_t" class="mb-1 pt-1">
                  Successfully password reset
                </h5>
                <p class="text-dark">
                  You can use your new password to login to your account
                </p>

                <!-- Login button -->
                <button
                  class="btn btn-lg btn-primary mt-3 w-60"
                  tabindex="0"
                  type="button"
                  routerLink="/login"
                  value="Login"
                >
                  Login Now
                </button>
                <!-- End Login button -->
              </div>
            </ng-template>
            <!-- End Reset Password Success Msg -->

            <!-- Reset Password Expiry Msg -->
            <ng-template #resetExpiry>
              <div class="reset_success text-center">
                <span
                  class="bi-exclamation-triangle alert_icon text-danger"
                ></span>
                <h5 class="mb-1 text-danger">Link Expired</h5>
                <p class="text-dark mb-0">
                  The password link has been expired or is not valid.
                  <br />Please request for a new forgot password link.
                </p>

                <!-- Forgot Password button -->
                <div class="d-block">
                  <a
                    class="btn btn-lg btn-primary my-2 w-60"
                    tabindex="0"
                    type="button"
                    (click)="ForgotPswd()"
                    value="Forgot Password"
                  >
                    Forgot Password
                  </a>
                </div>
                <!-- End Forgot Password button -->
                <!-- Login button -->
                <button
                  class="btn text-primary"
                  tabindex="0"
                  type="button"
                  routerLink="/login"
                  value="Login"
                >
                  Login Now
                </button>
                <!-- End Login button -->
              </div>
            </ng-template>
            <!-- End Reset Password Expiry Msg -->
          </div>
        </div>
      </div>
      <!-- END Reset Password -->
    </div>
    <!-- End Row -->
  </div>
  <!-- End Content -->
</div>

<!-- ========== END MAIN CONTENT ========== -->
