import { ActionReducerMap } from '@ngrx/store';
import * as fromAuth from 'src/app/login/store/login.reducers';
import * as fromDashboard from 'src/app/customer/dashboard/store/dashboard.reducers';
import * as fromtemplate from 'src/app/customer/templates/store/template.reducers';
import * as fromConsent from 'src/app/customer/consent-management/store/constent.reducers';
import * as fromCommon from 'src/app/store/common.reducer';
import * as fromCampaign from 'src/app/customer/campaign-manager/store/campaign.reducers';
import * as fromRoles from 'src/app/control-center/role-management/store/role.reducers';
import * as fromProfiles from 'src/app/control-center/profiles-management/store/profiles.reducers';
import * as fromCustAnalytics from 'src/app/customer/analytics/store/analytics.reducers';
import * as fromReports from 'src/app/customer/reports/store/reports.reducers';

export interface AppState {
  loginRes: fromAuth.State;
  loggedInUserDetails: fromCommon.AuthUserState;
  commondata: fromCommon.CommonState;
  loggedInSsoUserDetails: fromCommon.SsoAuthUserState;
  dashboarddata: fromDashboard.State;
  templateActions: fromtemplate.State;
  consentdata: fromConsent.ConsentState;
  templatePreviewPayload: fromtemplate.TemplatePreviewState;
  campaignActionData: fromCampaign.State;
  campaignPreview: fromCampaign.CampaignPreviewState;
  roleData: fromRoles.State;
  cust_analytics: fromCustAnalytics.State;
  profiledata: fromProfiles.State;
  reportdata: fromReports.ReportTemplatesState;
}

export const appReducer: ActionReducerMap<AppState> = {
  loginRes: fromAuth.loginreducer,
  loggedInUserDetails: fromCommon.userreducer,
  commondata: fromCommon.commonreducer,
  loggedInSsoUserDetails: fromCommon.userssoreducer,
  dashboarddata: fromDashboard.reducer,
  templateActions: fromtemplate.reducer,
  consentdata: fromConsent.consentreducer,
  templatePreviewPayload: fromtemplate.templatepreviewreducer,
  campaignActionData: fromCampaign.reducer,
  campaignPreview: fromCampaign.campaignpreviewreducer,
  roleData: fromRoles.reducer,
  cust_analytics: fromCustAnalytics.analyticsreducer,
  profiledata: fromProfiles.reducer,
  reportdata: fromReports.reducer,
};
