import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { WhitelabelingData } from '../appdata/initialwlb.data';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { SsoActions } from '../store/common.actions';
import { Store } from '@ngrx/store';
import * as authactions from '../login/store/login.actions';
import { Subject, takeUntil } from 'rxjs';
import { selectSsoProfilesDtlsData } from '../store/common.selectors';
import { SsoService } from '../services/sso.service';
@Component({
  selector: 'app-support-admin',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportAdminComponent implements AfterViewInit, OnInit, OnDestroy {
  displayTemplate!: TemplateRef<any>;
  whiteLabeledData: any = WhitelabelingData;
  @ViewChild('support') private support: TemplateRef<any>;
  profile: any;
  metadata: boolean = false;
  clientId: string;
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private cd: ChangeDetectorRef,
    private modalService: NgbModal,
    private store: Store,
    private ssoservice: SsoService
  ) {}

  ngOnInit(): void {
    if (environment.sso) {
      this.store
        .select(selectSsoProfilesDtlsData)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res) => {
          if (res) {
            if (res.profileData) {
              this.profile = res.profileData;
            }
            if (res.loggedInUserDetails) {
              this.metadata = true;
            } else {
              this.ssoservice.clearSesssion();
              this.metadata = false;
            }
            if (res.clientId) {
              this.clientId = res.clientId;
            }
          }
        });
    }
  }
  ngAfterViewInit(): void {
    this.displayTemplate = this.support;
    this.cd.detectChanges();
  }

  openVerticallyCentered(content: any) {
    this.modalService.open(content, { centered: true });
  }

  signout() {
    this.modalService.dismissAll();
    if (environment.sso) {
      if (this.clientId) {
        this.store.dispatch(
          SsoActions.logoutUser({ data: { clientId: this.clientId } })
        );
      }
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
