import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import { WhiteLabelGuard } from './services/whitelabel.gaurd';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { FbEmbeddedComponent } from './fb-embedded/fb-embedded.component';
import { SsoGuard } from './services/sso.guard';
import { environment } from 'src/environments/environment';
import { SsoValidationComponent } from './sso-validation/sso-validation.component';
import { ClientRedirectComponent } from './client-redirect/client-redirect.component';
import { checkssoclientGuard } from './services/checkssoclient.guard';
import { SupportAdminComponent } from './support-admin/support.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: environment.sso ? SsoValidationComponent : LoginComponent,
    canActivate: [WhiteLabelGuard],
  },
  {
    path: 'reset-password/:id',
    component: ResetpasswordComponent,
    canActivate: [SsoGuard],
  },
  {
    path: 'embedded-signup',
    component: FbEmbeddedComponent,
    canActivate: [SsoGuard],
  },
  {
    path: 'customer',
    loadChildren: () =>
      import('./customer/customer-routing.module').then(
        (m) => m.CustomerRoutingModule
      ),
  },
  {
    path: 'cc',
    loadChildren: () =>
      import('./control-center/control-center-routing.module').then(
        (m) => m.ControlCenterRoutingModule
      ),
  },
  {
    path: 'sa',
    loadChildren: () =>
      import('./super-admin/super-admin-routing.module').then(
        (m) => m.SuperAdminRoutingModule
      ),
  },
  {
    path: 'support-admin',
    component: SupportAdminComponent,
    canActivate: [checkssoclientGuard],
  },
  {
    path: 'page-not-found',
    component: PagenotfoundComponent,
    children: [{ path: 'noaccess', component: PagenotfoundComponent }],
  },
  {
    path: '**',
    component: environment.sso
      ? ClientRedirectComponent
      : PagenotfoundComponent,
    canActivate: [checkssoclientGuard],
    // redirectTo: 'page-not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
