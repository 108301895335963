<!-- Main Content Container -->
<main role="main" class="main" id="cc_business_accounts">
  <div class="content container-fluid">
    <ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>
  </div>
</main>
<!-- END Main Content Container -->

<!-- White Labeling Template -->
<ng-template #whiteLabelSettings>
  <!-- Header -->
  <div class="card card-sticky mb-2">
    <div class="top_header_options d-flex align-items-center justify-content-between">
      <h3 class="stat_main_title">White Labeling</h3>

      <ul class="list-inline list-inline-flex mb-0">
        <li class="list-inline-item">
          <button class="btn btn-default" type="button" (click)="restoreToDefault()">
            <i class="bi-arrow-counterclockwise"></i> Restore All to Default
          </button>
        </li>
        <li class="list-inline-item">
          <button type="submit" class="btn btn-primary" [ngClass]="showBtnLoader? 'btn-loading' : ''"
            (click)="onSubmit(whitelabelingForm)">
            <span class="button_text">Save Changes</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <!-- END Header -->

  <!-- Template Content -->
  <form [formGroup]="whitelabelingForm">
    <div class="card my-2">
      <div class="card-body">
        <!-- Product Logo -->
        <div class="row align-items-center">
          <div class="col-5">
            <h4 class="heading">Product Logo</h4>
            <small class="text-muted">Recommended image format is SVG (250x50px).</small>
          </div>
          <div class="col-7 align-items-center d-flex justify-content-between">
            <img [src]="whitelabelingForm.get('logo').value" alt="main-logo" class="w-10" />
            <ul class="list-inline list-inline-flex mb-0">
              <li class="list-inline-item">
                <label class="btn btn-sm btn-primary" for="productLogo">
                  <span> Change</span>
                  <input id="productLogo" 
                      name="productLogo"
                      type="file"
                      class="file-btn-input"
                      accept=".jpg, .jpeg, .png, .svg"
                      (change)="importImg($event, 'logo'); $event.target['value'] = ''" 
                  />
                </label>
              </li>
              <li class="list-inline-item">
                <button class="btn btn-sm btn-icon btn-default" type="button" [disabled]="
                    this.wlb_data.logo === whitelabelingForm.get('logo').value
                  " (click)="restoreElement('logo')">
                  <i class="bi bi-arrow-counterclockwise"></i>
                </button>
              </li>
            </ul>
          </div>
        </div>
        <!-- END Product Logo -->

        <mat-divider class="my-4"></mat-divider>

        <!-- Favicon -->
        <div class="row align-items-center">
          <div class="col-5">
            <h4 class="heading">Favicon</h4>
            <small class="text-muted">Recommended image format is PNG (16x16 or 32x32).</small>
          </div>
          <div class="col-7 align-items-center d-flex justify-content-between">
            <img [src]="whitelabelingForm.get('favicon').value" alt="favicon" class="w-10" />
            <!-- List -->
            <ul class="list-inline list-inline-flex mb-0">
              <li class="list-inline-item">
                <label class="btn btn-sm btn-primary" for="productFavicon">
                  <span> Change</span>
                  <input id="productFavicon" 
                      name="productFavicon"
                      type="file"
                      class="file-btn-input"
                      accept=".jpg, .jpeg, .png, .svg, .ico"
                      (change)="importImg($event, 'favicon'); $event.target['value'] = ''"
                    />
                </label>
              </li>
              <li class="list-inline-item">
                <button class="btn btn-sm btn-icon btn-default" type="button" [disabled]="
                    this.wlb_data.favicon ===
                    whitelabelingForm.get('favicon').value
                  " (click)="restoreElement('favicon')">
                  <i class="bi bi-arrow-counterclockwise"></i>
                </button>
              </li>
            </ul>
            <!-- End List -->
          </div>
        </div>
        <!-- END Favicon -->

        <mat-divider class="my-4"></mat-divider>

        <!-- Login Background -->
        <div class="row align-items-center">
          <div class="col-5">
            <h4 class="heading">Login Background</h4>
            <small class="text-muted">Recommended to use a large image, at least 1920x1080.</small>
          </div>
          <div class="col-7 align-items-center d-flex justify-content-between">
            <img [src]="whitelabelingForm.get('loginbackgroundimg').value" alt="loginimg" class="w-10" />
            <!-- List -->
            <ul class="list-inline list-inline-flex mb-0">
              <li class="list-inline-item">
                <label class="btn btn-sm btn-primary" for="loginBgImg">
                  <span> Change</span>
                  <input id="loginBgImg" 
                    name="loginBgImg"
                    type="file"
                    accept=".jpg, .jpeg, .png, .svg"
                    class="file-btn-input"
                    (change)="importImg($event, 'loginbackgroundimg');$event.target['value'] = ''"
                  />
                </label>
              </li>
              <li class="list-inline-item">
                <button class="btn btn-sm btn-icon btn-default" type="button" 
                  [disabled]="this.wlb_data.loginbackgroundimg === whitelabelingForm.get('loginbackgroundimg').value"
                  (click)="restoreElement('loginbackgroundimg')">
                  <i class="bi bi-arrow-counterclockwise"></i>
                </button>
              </li>
            </ul>
            <!-- End List -->
          </div>
        </div>
        <!-- END Login Background -->
      </div>
    </div>

    <div class="card my-2">
      <div class="card-body">
        <!-- Primary & Secondary Colors -->
        <div class="form-group">
          <!-- FormGroup -->
          <div class="row align-items-center">
            <div class="col-5">
              <h4>Colors</h4>
              <small class="text-muted">Select your brand colors.</small>
            </div>
            <div class="col-7 d-flex align-items-center justify-content-between">
              <ul class="list-inline list-inline-flex mb-0">
                <!-- Primary Color -->
                <li class="list-inline-item">
                  <div class="input-group input-group-merge" matTooltip="Primary Color" matTooltipPosition="above">
                    <div class="input-group-prepend input-group-text" id="primary">
                      <input type="color" id="primarycolor" class="input-color" name="primary_color"
                          formControlName="primary_color" />
                    </div>
                    <input type="text" class="form-control pl-6" id="primary"
                      [value]="whitelabelingForm.get('primary_color').value" />
                  </div>
                </li>
                <!-- END Primary Color -->

                <!-- Secondary Color -->
                <li class="list-inline-item">
                  <div class="input-group input-group-merge" matTooltip="Secondary Color" matTooltipPosition="above">
                    <div class="input-group-prepend input-group-text" id="secondary">
                      <input type="color" id="secondarycolor" class="input-color" name="secondary_color"
                      formControlName="secondary_color" />
                    </div>
                    <input type="text" class="form-control pl-6" id="secondary"
                      [value]="whitelabelingForm.get('secondary_color').value" />
                  </div>
                </li>
                <!-- END Secondary Color -->
              </ul>
              <button class="btn btn-icon btn-default" type="button" [disabled]="
                      wlb_data.primary_color ===
                        whitelabelingForm.get('primary_color').value &&
                      wlb_data.secondary_color ===
                        whitelabelingForm.get('secondary_color').value
                    " (click)="
                      restoreElement('primary_color');
                      restoreElement('secondary_color')
                    ">
                    <i class="bi bi-arrow-counterclockwise"></i>
                  </button>
            </div>
          </div>
          <!-- end FormGroup -->
        </div>
        <!-- END Primary & Secondary Colors -->

        <mat-divider class="my-4"></mat-divider>

        <!-- Buttons Style -->
        <div class="form-group">
          <div class="row align-items-center">
            <div class="col-5">
              <h4 class="heading">Buttons</h4>
              <small class="text-muted">Select buttons type.</small>
            </div>
            <div class="col-7 d-flex justify-content-between align-items-center">
              <mat-radio-group class="d-flex justify-content-between w-50" aria-label="Select an option" formControlName="buttons_type">
                <mat-radio-button value="rounded" checked="true">Rounded (Default)</mat-radio-button>
                <mat-radio-button value="pilled">Pilled</mat-radio-button>
              </mat-radio-group>
              <button class="btn btn-sm btn-icon btn-default" type="button" (click)="restoreElement('buttons_type')"
              [disabled]="
                wlb_data.buttons_type ===
                whitelabelingForm.get('buttons_type').value
              ">
              <i class="bi bi-arrow-counterclockwise"></i>
            </button>
              
            </div>
          </div>
        </div>
        <!-- END Buttons Style -->

        <mat-divider class="my-4"></mat-divider>

        <!-- Form Control Style -->
        <div class="form-group mb-0">
          <!-- FormGroup -->
          <div class="row align-items-center">
            <div class="col-5">
              <h4 class="heading">Form Control</h4>
              <small class="text-muted">Select input type.</small>
            </div>
            <div class="col-7 d-flex justify-content-between align-items-center">
              <mat-radio-group class="d-flex justify-content-between w-50" aria-label="Select an option" formControlName="formcontrol_type">
                <mat-radio-button value="rounded" checked="true">Rounded (Default)</mat-radio-button>
                <mat-radio-button value="pilled">Pilled</mat-radio-button>
              </mat-radio-group>
              <button class="btn btn-sm btn-icon btn-default" type="button" (click)="restoreElement('formcontrol_type')">
                <i class="bi bi-arrow-counterclockwise"></i>
              </button>
            </div>
          </div>
          <!-- end FormGroup -->
        </div>
        <!-- END Form Control Style -->
      </div>
    </div>

    <div class="card my-2">
      <div class="card-body">
        <!-- Product Name -->
        <div class="form-group">
          <div class="row">
            <div class="col-5">
              <h4 class="mb-0">Product Name</h4>
              <small class="text-muted">If the Logo is not available, entered text will be
                replaced.</small>
            </div>

            <div class="col-7">
              <input type="text" class="form-control" value="Engagejet" formControlName="product_name"
                placeholder="Enter product name" />
              <!-- Product Name Error -->
              <div class="invalid-feedback">
                <div
                  *ngIf="
                    wFC['product_name'].touched ||
                    wFC['product_name'].dirty
                  "
                >
                  <ng-container
                    *ngIf="
                      wFC['product_name'].value === '' ||
                        wFC['product_name'].value === null;
                      else fcInvalid
                    "
                  >
                    Product name can not be empty.
                  </ng-container>
                  <ng-template #fcInvalid>
                    <span *ngIf="wFC['product_name'].errors">
                      Please enter a valid product name.
                    </span>
                  </ng-template>
                </div>
              </div>
              <!-- END Product Name Error -->
            </div>
          </div>
        </div>
        <!-- END Product Name -->

        <!-- Company Name -->
        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <h4 class="mb-0">Company Name</h4>
              <small class="text-muted">Enter registered company.</small>
            </div>

            <div class="col-sm-7">
              <input type="text" class="form-control" value="Nuacem AI" formControlName="company_name"
                placeholder="Registered company name" />
              <!-- Company Name Error -->
              <div class="invalid-feedback">
                <div
                  *ngIf="
                    wFC['company_name'].touched ||
                    wFC['company_name'].dirty
                  "
                >
                  <ng-container
                    *ngIf="
                      wFC['company_name'].value === '' ||
                        wFC['company_name'].value === null;
                      else fcInvalid
                    "
                  >
                    Company name can not be empty.
                  </ng-container>
                  <ng-template #fcInvalid>
                    <span *ngIf="wFC['company_name'].errors">
                      Please enter a valid company name.
                    </span>
                  </ng-template>
                </div>
              </div>
              <!-- END Company Name Error -->
            </div>
          </div>
        </div>
        <!-- END Company Name -->

        <!-- Support Email -->
        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <h4 class="mb-0">Support Email</h4>
              <small class="text-muted">Enter your business support email id.</small>
            </div>
            <div class="col-sm-7">
              <input type="email" class="form-control" formControlName="sprtEmail"
                placeholder="Enter business support email. Ex: abc@companyname.com" />
              <!-- Support email error -->
              <div
                class="invalid-feedback"
              >
                <div
                  *ngIf="
                    wFC['sprtEmail'].touched ||
                    wFC['sprtEmail'].dirty
                  "
                >
                  <ng-container
                    *ngIf="
                      wFC['sprtEmail'].value === '' ||
                        wFC['sprtEmail'].value === null;
                      else fnInvalid
                    "
                  >
                    Business support email can not be empty.
                  </ng-container>
    
                  <ng-template #fnInvalid>
                    <span *ngIf="wFC['sprtEmail'].errors">
                      Please enter a valid email ID format(ex:
                      abc&#64;domain.com)
                    </span>
                  </ng-template>
                </div>
              </div>
              <!-- End Support email error -->
            </div>
          </div>
        </div>
        <!-- END Support Email -->

        <!-- Support Number -->
        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <h4 class="mb-0">Support Number</h4>
              <small class="text-muted">Enter your business support contact number.</small>
            </div>
            <div class="col-sm-7">
              <input type="tel" class="form-control" formControlName="sprtNumber"
                placeholder="Enter business support number." />
              <!-- Support Number Error -->
              <div class="invalid-feedback">
                <div
                  *ngIf="
                    wFC['sprtNumber'].touched ||
                    wFC['sprtNumber'].dirty
                  "
                >
                  <ng-container
                    *ngIf="
                      wFC['sprtNumber'].value === '' ||
                        wFC['sprtNumber'].value === null;
                      else fcInvalid
                    "
                  >
                    Support number can not be empty.
                  </ng-container>
                  <ng-template #fcInvalid>
                    <span *ngIf="wFC['sprtNumber'].errors">
                      Please enter a valid support number.
                    </span>
                  </ng-template>
                </div>
              </div>
              <!-- END Support Number Error -->
            </div>
          </div>
        </div>
        <!-- END Support Number -->

        <!-- Website URL -->
        <div class="form-group">
          <div class="row">
            <div class="col-sm-5">
              <h4 class="mb-0">Website</h4>
              <small class="text-muted">Enter your website url link.</small>
            </div>
            <div class="col-sm-7">
              <input type="text" class="form-control" value="www.nuacem.com" formControlName="website"
                placeholder="Enter website url. Ex:www.domain.com" />
              <!-- Website URL Error -->
              <div class="invalid-feedback">
                <div
                  *ngIf="
                    wFC['website'].touched ||
                    wFC['website'].dirty
                  "
                >
                  <ng-container
                    *ngIf="
                      wFC['website'].value === '' ||
                        wFC['website'].value === null;
                      else fcInvalid
                    "
                  >
                    Website URL can not be empty.
                  </ng-container>
                  <ng-template #fcInvalid>
                    <span *ngIf="wFC['website'].errors">
                      Please enter a valid website url.
                    </span>
                  </ng-template>
                </div>
              </div>
              <!-- END Website URL Error -->
            </div>
          </div>
        </div>
        <!-- END Website URL -->

        <!-- Terms -->
        <div class="form-group">
          <div class="row">
          <div class="col-sm-5">
            <h4 class="mb-0">Terms</h4>
            <small class="text-muted">Enter Terms and conditions page link.</small>
          </div>
          <div class="col-sm-7">
            <input type="text" class="form-control" value="www.nuacem.com/terms" formControlName="terms_url"
              placeholder="Terms website url" />
            <!-- Terms URL Error -->
            <div class="invalid-feedback">
              <div
                *ngIf="
                  wFC['terms_url'].touched ||
                  wFC['terms_url'].dirty
                "
              >
                <ng-container
                  *ngIf="
                    wFC['terms_url'].value === '' ||
                      wFC['terms_url'].value === null;
                    else fcInvalid
                  "
                >
                  Terms website URL can not be empty.
                </ng-container>
                <ng-template #fcInvalid>
                  <span *ngIf="wFC['terms_url'].errors">
                    Please enter a valid terms website url.
                  </span>
                </ng-template>
              </div>
            </div>
            <!-- END Terms URL Error -->
          </div>
        </div>
      </div>
      
      <!-- END Terms -->


      <mat-divider class="my-3"></mat-divider>

      <!-- Poweredby -->
      <div class="row align-items-center">
        <div class="col-5">
          <h4 class="heading mb-0">Powered by</h4>
          <small class="text-muted">Recommended image format is SVG (180x50px).</small>
        </div>
        <div class="col-7 align-items-center d-flex justify-content-between">
          <img [src]="whitelabelingForm.get('poweredbyimg').value" alt="poweredbyimg" class="w-20" />
          <!-- List -->
          <ul class="list-inline list-inline-flex mb-0">
            <li class="list-inline-item">
              <label class="btn btn-sm btn-primary" for="poweredbyimg">
                <span> Change</span>
                <input id="poweredbyimg"
                  name="custom-file"
                  type="file"
                  accept=".jpg, .jpeg, .png, .svg"
                  class="file-btn-input"
                  (change)="importImg($event, 'poweredbyimg'); $event.target['value'] = ''"
                />
              </label>
            </li>

            <li class="list-inline-item">
              <button class="btn btn-sm btn-icon btn-default" type="button" (click)="restoreElement('poweredbyimg')"
                [disabled]="
                    wlb_data.poweredbyimg ===
                    whitelabelingForm.get('poweredbyimg').value
                  ">
                <i class="bi bi-arrow-counterclockwise"></i>
              </button>
            </li>
          </ul>
          <!-- End List -->
        </div>
      </div>
      <!-- END Poweredby -->
    </div>
    </div>
  </form>
  <!-- END Template Content -->
</ng-template>
<!-- END White Labeling Template -->