import { Injectable } from '@angular/core';
import { act, Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, exhaustMap, catchError, withLatestFrom } from 'rxjs/operators';
import { SharedService } from '../../../services/shared.service';
import {
  getFiletrsData,
  GetSvCustomers,
  GetSvWallets,
} from './serviceaccount.actions';
import {
  SvcustomersResponse,
  SvwalletsResponse,
} from 'src/app/appdata/shared.data';
import { Store } from '@ngrx/store';
import * as fromCustomers from './serviceaccount.reducers';
import { concatLatestFrom } from '@ngrx/operators';
import { selectSvwalletsdata } from './serviceaccountwallets.reducers';

@Injectable()
export class ServiceAccountEffects {
  constructor(
    private actions$: Actions,
    private apiservice: SharedService,
    private readonly store: Store
  ) {}

  getGetSvCustomers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetSvCustomers.getSVCustomers, getFiletrsData),
      exhaustMap((action) =>
        this.apiservice
          .getSVCustomers(
            action.type === getFiletrsData.type
              ? action.customerParams
              : action.params
          )
          .pipe(
            withLatestFrom(
              this.store.select(fromCustomers.selectSvcustomerdata)
            ),
            map(
              ([response, previousData]: [
                SvcustomersResponse,
                SvcustomersResponse['data']
              ]) => {
                const newData = response.data;
                const params =
                  action.type === getFiletrsData.type
                    ? action.customerParams
                    : action.params;
                const customers_data =
                  action.type === getFiletrsData.type ||
                  (action.type === GetSvCustomers.getSVCustomers.type &&
                    !action?.filter)
                    ? newData.customers_data
                    : (params.sv_customer_id || params.wallet_name) &&
                      params.page !== 1
                    ? [
                        ...previousData.customers_data,
                        ...newData.customers_data,
                      ]
                    : (!params.sv_customer_id || !params.wallet_name) &&
                      params.page !== 1
                    ? [
                        ...previousData.customers_data,
                        ...newData.customers_data,
                      ]
                    : newData.customers_data;

                return GetSvCustomers.getSVCustomersSuccess({
                  data: { count: response.data.count, customers_data },
                });
              }
            ),
            catchError((error: any) =>
              of(GetSvCustomers.getSVCustomersFailure(error))
            )
          )
      )
    );
  });

  getGetSvWallets$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GetSvWallets.getSVWallets, getFiletrsData),
      exhaustMap((action) =>
        this.apiservice
          .getSVWallets(
            action.type === getFiletrsData.type
              ? action.walletParams
              : action.params
          )
          .pipe(
            withLatestFrom(this.store.select(selectSvwalletsdata)),
            map(
              ([response, previousData]: [
                SvwalletsResponse,
                SvwalletsResponse['data']
              ]) => {
                const newData = response.data;
                const params =
                  action.type === getFiletrsData.type
                    ? action.walletParams
                    : action.params;
                const wallets_data =
                  action.type === getFiletrsData.type ||
                  (action.type === GetSvWallets.getSVWallets.type &&
                    !action?.filter)
                    ? newData.wallets_data
                    : (params.sv_customer_id || params.wallet_name) &&
                      params.page !== 1
                    ? [...previousData.wallets_data, ...newData.wallets_data]
                    : (!params.sv_customer_id || !params.wallet_name) &&
                      params.page !== 1
                    ? [...previousData.wallets_data, ...newData.wallets_data]
                    : newData.wallets_data;
                return GetSvWallets.getSVWalletsSuccess({
                  data: { count: response.data.count, wallets_data },
                });
              }
            ),
            catchError((error: any) =>
              of(GetSvWallets.getSVWalletsFailure(error))
            )
          )
      )
    );
  });
}
