import { HttpClient } from '@angular/common/http';
import { Injectable, Pipe } from '@angular/core';
import { map, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SsoLoginData, SsoPayload } from '../appdata/auth.model';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  constructor(private http: HttpClient) {}

  ssoTokenValidation(payload: SsoPayload) {
    const url = environment.backendurl + `/api/auth/sso-token-validation`;
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  ssoLogin(payload: SsoPayload) {
    const url =
      environment.backendurl + `/api/auth/initial-sso-token-validation`;
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res as SsoLoginData;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  ssoLogut(payload: SsoPayload) {
    const url = environment.backendurl + `/api/auth/logout-sso-token`;
    return this.http.post(url, payload).pipe(
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  decodeToken(token: string): any {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );
      return JSON.parse(jsonPayload);
    } catch (error) {
      catchError((error) => {
        return throwError(() => error);
      });
      //return throwError(() => error);
    }
  }
  clearSesssion() {
    sessionStorage.clear();
  }
}
