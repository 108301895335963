<!-- ========== MAIN CONTENT ========== -->
<app-alert></app-alert>
<div id="cst_login_screen" class="main pt-0">
  <!-- Content -->
  <div class="container-fluid">
    <div class="row">
      <!-- Login Left Side -->
      <div
        class="col-md-7 cst_login_left d-flex min-vh-100 position-relative px-0 p-lg-4 p-sm-0"
      >
        <div class="brand_logo">
          <img
            id="login_main_logo"
            [src]="whiteLabeledData?.logo"
            alt="Smartping AI"
          />
        </div>
      </div>
      <!-- End Login Left Side -->

      <!-- Login Right Side -->
      <div
        class="col-md-5 cst_login_right d-flex justify-content-center align-items-center min-vh-lg-100 p-xl-4 p-sm-0"
      >
        <div class="w-80 py-5 p-xl-0 p-lg-4 p-md-0">
          <div class="cst_login_sec">
            <ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>
            <!-- SHOW LOGIN FORM -->
            <ng-template #showLogin>
              <!-- Log In Form Tittle -->
              <div class="mb-6">
                <h1 class="fw-bold mb-4" id="eb_title">Embedded Signup</h1>
              </div>
              <!-- Login with FB button -->
              <button
                class="btn btn-lg btn-block fb_btn my-2 pointer"
                tabindex="0"
                type="button"
                (click)="fbSignUp()"
              >
                <em class="bi bi-facebook btn_icon me-2"></em>
                <span>Signup with Facebook</span>
              </button>
              <!-- End Login with FB button -->

              <div class="imp_notes mt-4">
                <h4>Note:</h4>
                <ol class="small">
                  <li>View guidelines video by META <a href="https://www.youtube.com/watch?v=Us90Q8aV0TU" target="_blank">Click here</a></li>
                  <li>View guidelines PDF document <a href="/assets/files/WABA_embedded_sign_up_onboarding_process.pdf" target="_blank">Click here</a></li>
                  <li>It is suggested to login with your Registered <strong>Facebook Business Account</strong></li>
                  <li>For support: Dial <a href="tel:1800-911-1111">1800-911-1111</a> (or) Email at <a href="mailto:support.waba@smartping.ai">support.waba&#64;smartping.ai</a></li>
                </ol>
              </div>
            </ng-template>
            <!-- End SHOW LOGIN FORM -->
          </div>
        </div>
        <p class="small text-center footer_copyrights">Copyright &#169; SMARTPING AI PRIVATE LIMITED. All Rights Reserved | <a href="https://smartping.ai/privacy-policy" target="_blank" class="text-primary">Privacy Policy</a></p>
      </div>
      <!-- END Login Right Side -->
    </div>
    <!-- End Row -->
  </div>
  <!-- End Content -->
</div>
<!-- ========== END MAIN CONTENT ========== -->