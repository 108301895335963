import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, interval, Subject, takeUntil } from 'rxjs';
import { SharedService } from './services/shared.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { SsoActions } from './store/common.actions';
import { selectSsoProfilesDtlsData } from './store/common.selectors';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  clientId: string;
  token: string;
  constructor(
    private shareservice: SharedService,
    private readonly store: Store
  ) {}
  title = 'waba';
  ngOnInit(): void {
    this.store
      .select(selectSsoProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.clientId = res.clientId;
        this.token = res.token;
      });
    if (environment.sso) {
      fromEvent(window, 'focus')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          if (this.clientId) {
            this.store.dispatch(
              SsoActions.validateSsoToken({
                payload: { clientId: this.clientId },
              })
            );
          }
        });
      if (this.token && this.clientId) {
        const tokenDetails = JSON.parse(atob(this.token.split('.')[1]));
        interval(
          tokenDetails?.session_validation_frequency
            ? tokenDetails?.session_validation_frequency
            : 15000
        )
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            const expiration = JSON.parse(atob(this.token.split('.')[1])).exp;
            if (expiration < Math.floor(new Date().getTime() / 1000)) {
              this.store.dispatch(
                SsoActions.validateSsoToken({
                  payload: { clientId: this.clientId },
                })
              );
            }
          });
      }
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
