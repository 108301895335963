<!-- ========== MAIN CONTENT ========== -->
<app-alert></app-alert>
<div id="cst_login_screen" class="main pt-0">
  <!-- Content -->
  <div class="container-fluid">
    <div class="row">
      <!-- Login Left Side -->
      <div
        class="col-md-7 cst_login_left d-flex min-vh-100 position-relative px-0 p-lg-2 p-sm-0"
      >
        <div class="brand_logo">
          <img
            class="img-fluid"
            id="login_main_logo"
            [src]="whiteLabeledData?.logo"
            alt="Company Logo"
          />
        </div>
      </div>
      <!-- End Login Left Side -->

      <!-- Login Right Side -->
      <div
        class="col-md-5 cst_login_right d-flex justify-content-center align-items-center min-vh-lg-100 p-xl-4 p-sm-0"
      >
        <div class="w-85 py-5 p-xl-0 p-lg-4 p-md-0">
          <div class="cst_login_sec">
            <ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>

            <!-- SHOW LOGIN FORM -->
            <ng-template #showLogin>
              <!-- Access Denied Error -->
              <div class="invalid-feedback" *ngIf="showAccessDenied">
                <em class="bi-exclamation-triangle-fill"></em> This user doesn’t
                exist. Try another account or
                <a (click)="requestAccess()" class="fw-bold text-secondary"
                  >Request for Access</a
                >
                instead.
              </div>
              <!-- END Access Denied Error -->

              <!-- Login form start -->
              <form
                id="cst_login_form"
                [formGroup]="loginForm"
                (ngSubmit)="login(loginForm)"
              >
                <!-- Log In Form Tittle -->
                <div class="mb-6">
                  <h1 class="login_title">Login to your account</h1>
                </div>

                <!-- Email Form Group -->
                <div class="mb-4">
                  <label class="input-label" for="loginEmail"
                    >Email ID
                    <span class="text-danger" matTooltip="Email Id is mandatory"
                      >*</span
                    >
                  </label>
                  <div class="input-group input-group-merge mb-0">
                    <div class="input-group-prepend input-group-text">
                      <em class="bi bi-envelope"></em>
                    </div>
                    <input
                      type="email"
                      formControlName="useremail"
                      class="form-control form-control-lg"
                      name="email"
                      id="loginEmail"
                      placeholder="Enter business email ID"
                      tabindex="0"
                      autocomplete="off"
                    />
                  </div>
                  <!-- Login email error -->
                  <div
                    id="loginEmail-error"
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="
                        loginFC['useremail'].touched ||
                        loginFC['useremail'].dirty
                      "
                    >
                      <ng-container
                        *ngIf="
                          loginFC['useremail'].value === '' ||
                            loginFC['useremail'].value === null;
                          else fnInvalid
                        "
                      >
                        Please enter business email ID.
                      </ng-container>

                      <ng-template #fnInvalid>
                        <span *ngIf="loginFC['useremail'].errors">
                          Please enter a valid business email ID format(ex:
                          abc&#64;domain.com)
                        </span>
                      </ng-template>
                    </div>
                  </div>
                  <!-- End Login email error -->
                </div>
                <!-- End Email Form Group -->

                <!-- Password Form Group -->
                <div class="login_pwd_sec">
                  <label class="input-label" for="cst_login_pwd"
                    >Password
                    <span class="text-danger" matTooltip="Password is mandatory"
                      >*</span
                    ></label
                  >
                  <div class="input-group input-group-merge mb-0">
                    <div class="input-group-prepend input-group-text">
                      <em class="bi bi-lock"></em>
                    </div>
                    <input
                      formControlName="cst_login_password"
                      [type]="showPassword ? 'text' : 'password'"
                      class="form-control form-control-lg"
                      placeholder="Enter your password"
                      name="password"
                      id="cst_login_pwd"
                      tabindex="0"
                      autocomplete="off"
                    />
                    <div
                      class="input-group-append input-group-text changePassTarget"
                    >
                      <em
                        class="changePassIcon bi"
                        [ngClass]="{
                          'bi-eye': !showPassword,
                          'bi-eye-slash': showPassword
                        }"
                        (click)="showPassword = !showPassword"
                        (keypress)="showPassword = !showPassword"
                      >
                      </em>
                    </div>
                  </div>

                  <!-- Login password error -->
                  <div
                    id="loginPassword-error"
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="
                        loginFC['cst_login_password'].touched ||
                        loginFC['cst_login_password'].dirty
                      "
                    >
                      <ng-container
                        *ngIf="
                          loginFC['cst_login_password'].value === '' ||
                          loginFC['cst_login_password'].value === null
                        "
                      >
                        Please enter valid password associated to above email ID
                      </ng-container>
                    </div>
                  </div>
                  <!-- End Login password error -->
                </div>
                <!-- Password Form Group -->

                <!-- Forgot Password -->
                <div
                  class="justify-content-between align-items-center d-flex my-4 float-end"
                >
                  <p>
                    <a
                      class="btn-link text-primary"
                      data-toggle="modal"
                      tabindex="0"
                      (click)="forgotPassword()"
                    >
                      Forgot Password?</a
                    >
                  </p>
                </div>
                <!-- End Forgot Password -->

                <!-- Login button -->
                <button
                  class="btn btn-lg btn-block btn-primary my-2"
                  [ngClass]="showLoginLoader? 'btn-loading disabled':''"
                  tabindex="0"
                  type="submit"
                  value="Login"
                  [ngStyle]="{
                    cursor:
                      loginForm.invalid || showLoginLoader
                        ? 'not-allowed'
                        : 'pointer'
                  }"
                  [disabled]="loginForm.invalid || showLoginLoader"
                >
                  <span class="button_text"> Login</span>
                </button>
                <!-- End Login button -->

                <!-- Request for access -->
                <div class="my-3 text-center raf_pwd d-none">
                  Don't have account?
                  <a
                    class="mb-3"
                    data-toggle="modal"
                    tabindex="0"
                    (click)="requestAccess()"
                  >
                    Request Access</a
                  >
                </div>
                <!-- End Request for access -->
              </form>
              <!-- Login form END -->
            </ng-template>
            <!-- End SHOW LOGIN FORM -->

            <!-- SHOW FORGOT/RESET PASSWORD FORM -->
            <ng-template #showForgotPswd>
              <!-- Forgot Form Start -->
              <form
                class="forgot-password-fields"
                id="cst_forgotpwd_form"
                [formGroup]="forgotForm"
                (ngSubmit)="forgotPswd(forgotForm)"
              >
                <div class="mb-6">
                  <h1 class="login_title">Forgot your password?</h1>
                  <p class="login_sub_title">
                    Please provide the registered email address to receive unique password link.
                  </p>
                </div>

                <!-- Forgot Email Form Group -->
                <label class="input-label" for="forgotEmail"
                  >Email ID
                  <span class="text-danger" matTooltip="Password is mandatory"
                    >*</span
                  >
                </label>
                <div class="input-group input-group-merge">
                  <span class="input-group-prepend input-group-text">
                    <em class="bi bi-envelope gd_icon"></em>
                  </span>
                  <input
                    type="email"
                    formControlName="userEmail"
                    class="form-control form-control-lg"
                    name="email"
                    id="forgotEmail"
                    placeholder="Ex: yourname@companyname.com"
                    tabindex="0"
                    autocomplete="off"
                  />
                </div>
                <!-- End Forgot Email Form Group -->

                <!-- Forgot form email error -->
                <div id="forgotEmail-error" class="invalid-feedback">
                  <div
                    *ngIf="
                      fgtFC['userEmail'].touched || fgtFC['userEmail'].dirty
                    "
                  >
                    <ng-container
                      *ngIf="
                        fgtFC['userEmail'].value === '' ||
                          fgtFC['userEmail'].value === null;
                        else fnInvalid
                      "
                    >
                      Please enter business email ID.
                    </ng-container>

                    <ng-template #fnInvalid>
                      <span *ngIf="fgtFC['userEmail'].errors">
                        Please enter a valid business email ID format(ex:
                        abc&#64;domain.com)
                      </span>
                    </ng-template>
                  </div>
                </div>
                <!-- End Forgot form email error -->

                <!-- Reset Pwd Button -->
                <button
                  type="submit"
                  class="btn btn-lg btn-block btn-primary mt-3"
                  [ngStyle]="{
                    cursor:
                      forgotForm.status !== 'VALID' ? 'not-allowed' : 'pointer'
                  }"
                  [disabled]="forgotForm.invalid"
                >
                  Get Reset Password Link
                </button>
                <!-- END Reset Pwd Button -->

                <!-- Go back to Log in Button -->
                <a
                  class="text-primary back_to_login"
                  (click)="GoToLoginFields()"
                >
                  <i class="bi bi-chevron-left fs-6"></i> Back to Login
                </a>
                <!-- END Go back to Log in Button -->
              </form>
              <!-- End Forgot Form Start -->
            </ng-template>
            <!-- End SHOW FORGOT/RESET PASSWORD FORM -->

            <!-- SHOW Reset pwd mail sent success -->
            <ng-template #showResetPwdLink>
              <!-- Forgot Form Start -->
              <div class="forgot-password-fields" id="cst_forgotpwd_form">
                <div class="login-fields text-center">
                  <img
                    src="assets/img/mail_sent.svg"
                    alt="Reset Password Link Sent"
                  />
                  <h2 id="frgt_t" class="mb-1 pt-1">Check your mail</h2>
                  <p class="text-dark">
                    We have sent you an email that contains a link to reset your
                    password. The link gets expired in next 15min, please check your email.
                  </p>

                  <!-- Go back to Log in Button -->
                  <a
                    class="btn btn-block mt-3 text-primary"
                    (click)="GoToLoginFields()"
                  >
                    <i class="bi bi-chevron-left fs-6"></i> Back to Login
                  </a>
                  <!-- END Go back to Log in Button -->
                </div>
              </div>
              <!-- End Forgot Form Start -->
            </ng-template>
            <!-- End SHOW Reset pwd mail sent success -->

            <!-- SHOW REQUEST ACCESS FORM -->
            <ng-template #showRequestAccess>
              <!-- Request Access Form Start -->
              <form
                class="request-access-fields"
                [formGroup]="requestForm"
                (ngSubmit)="requestAccessEmail(requestForm)"
              >
                <div class="login-fields">
                  <div class="mb-4">
                    <h2 class="login_title">Request Access</h2>
                    <p class="login_sub_title">
                      Please enter the business information to get access to
                      your account.
                    </p>
                  </div>

                  <!-- Request Access Full Name -->
                  <label class="input-label" for="userRequestFirstname"
                    >Full Name
                    <span
                      class="text-danger"
                      matTooltip="Full Name is mandatory"
                      >*</span
                    ></label
                  >

                  <div class="row">
                    <!-- First Name -->
                    <div class="col pe-1">
                      <div class="input-group">
                        <span class="input-group-text">
                          <em class="bi bi-person-fill gd_icon"></em>
                        </span>
                        <input
                          type="text"
                          formControlName="userRequestFirstname"
                          class="form-control form-control-lg"
                          name="firstname"
                          id="userRequestFirstname"
                          tabindex="0"
                          placeholder="First Name"
                          data-msg="Please enter first name."
                        />
                      </div>
                      <!-- Request Access firstname error -->
                      <div
                        id="requestFirstname-error"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="
                            reqFC['userRequestFirstname']?.touched ||
                            reqFC['userRequestFirstname']?.dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              reqFC['userRequestFirstname'].value === '';
                              else fnInvalid
                            "
                          >
                            First name is required.
                          </ng-container>

                          <ng-template #fnInvalid>
                            <span *ngIf="!reqFC['userRequestFirstname'].valid">
                              No unwanted space, numeric and special characters
                              allowed.
                            </span>
                          </ng-template>
                        </div>
                      </div>
                      <!-- End Request Access firstname error -->
                    </div>
                    <!-- END First Name -->

                    <!-- Last Name -->
                    <div class="col ps-1">
                      <input
                        type="text"
                        formControlName="userRequestLastname"
                        class="form-control form-control-lg"
                        name="lastname"
                        id="userRequestLastname"
                        tabindex="0"
                        placeholder="Last Name"
                        data-msg="Please enter last name."
                      />
                      <!-- Request Access lastname error -->
                      <div
                        id="requestLastname-error"
                        class="invalid-feedback"
                      >
                        <div
                          *ngIf="
                            reqFC['userRequestLastname']?.touched ||
                            reqFC['userRequestLastname']?.dirty
                          "
                        >
                          <ng-container
                            *ngIf="
                              reqFC['userRequestLastname'].value === '';
                              else fnInvalid
                            "
                          >
                            Last name is required.
                          </ng-container>

                          <ng-template #fnInvalid>
                            <span *ngIf="!reqFC['userRequestLastname'].valid">
                              No unwanted space, numeric and special characters
                              allowed.
                            </span>
                          </ng-template>
                        </div>
                      </div>
                      <!-- End Request Access lastname error -->
                    </div>
                    <!-- END Last Name -->
                  </div>
                  <!-- END Request Access Full Name -->

                  <!-- Request Access Email Form Group -->
                  <label class="input-label" for="userRequestEmail"
                    >Email ID
                    <span
                      class="text-danger"
                      matTooltip="Offical Email ID is mandatory"
                      >*</span
                    ></label
                  >
                  <div class="input-group">
                    <span class="input-group-text">
                      <em class="bi bi-envelope-fill gd_icon"></em>
                    </span>
                    <input
                      type="email"
                      formControlName="userRequestEmail"
                      class="form-control form-control-lg"
                      name="email"
                      id="userRequestEmail"
                      tabindex="0"
                      autocomplete="off"
                      placeholder="Ex: yourname@companyname.com"
                      data-msg="Please enter a valid business email ID."
                    />
                  </div>

                  <!-- Request Access form email error -->
                  <div
                    id="requestEmail-error"
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="
                        reqFC['userRequestEmail'].touched ||
                        reqFC['userRequestEmail'].dirty
                      "
                    >
                      <ng-container
                        *ngIf="
                          reqFC['userRequestEmail'].value === '' ||
                            reqFC['userRequestEmail'].value === null;
                          else fnInvalid
                        "
                      >
                        Enter business email ID.
                      </ng-container>

                      <ng-template #fnInvalid>
                        <span *ngIf="reqFC['userRequestEmail'].errors">
                          Please enter a valid email ID format(ex:
                          abc&#64;domain.com)
                        </span>
                      </ng-template>
                    </div>
                  </div>
                  <!-- End Request Access  form email error -->
                  <!-- END Request Access Email Form Group -->

                  <!-- Request Access Mobile Number Form Group -->
                  <label class="input-label" for="userRequestPhone"
                    >Business Mobile Number
                    <span
                      class="text-danger"
                      matTooltip="Mobile Number is mandatory"
                      >*</span
                    ></label
                  >
                  <div class="input-group">
                    <span class="input-group-text">
                      <em class="bi bi-phone-fill gd_icon"></em>
                    </span>
                    <input
                      type="text"
                      formControlName="userRequestPhone"
                      class="form-control form-control-lg"
                      name="phonenumber"
                      id="userRequestPhone"
                      tabindex="0"
                      autocomplete="off"
                      placeholder="Enter your number"
                      data-msg="Please enter your mobile number."
                    />
                  </div>
                  <!-- Request Access mobile number error -->
                  <div
                    id="requestPhone-error"
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="
                        reqFC['userRequestPhone']?.touched ||
                        reqFC['userRequestPhone']?.dirty
                      "
                    >
                      <ng-container
                        *ngIf="
                          reqFC['userRequestPhone'].value === '';
                          else fnInvalid
                        "
                      >
                        Business mobile number is required.
                      </ng-container>

                      <ng-template #fnInvalid>
                        <span *ngIf="!reqFC['userRequestPhone'].valid">
                          No unwanted space and special characters allowed.
                        </span>
                      </ng-template>
                    </div>
                  </div>
                  <!-- End Request Access mobile number error -->
                  <!-- END Request Access Mobile Number Form Group -->

                  <!-- Request Access Company Form Group -->
                  <label class="input-label" for="userRequestCompany"
                    >Company Name
                    <span
                      class="text-danger"
                      matTooltip="Company Name is mandatory"
                      >*</span
                    ></label
                  >
                  <div class="input-group">
                    <span class="input-group-text">
                      <em class="bi bi-buildings-fill gd_icon"></em>
                    </span>
                    <input
                      type="text"
                      formControlName="userRequestCompany"
                      class="form-control form-control-lg"
                      name="companyname"
                      id="userRequestCompany"
                      tabindex="0"
                      autocomplete="off"
                      placeholder="Enter your company name"
                      data-msg="Please enter company name."
                    />
                  </div>
                  <!-- Request Access company error -->
                  <div
                    id="requestCompany-error"
                    class="invalid-feedback"
                  >
                    <div
                      *ngIf="
                        reqFC['userRequestCompany']?.touched ||
                        reqFC['userRequestCompany']?.dirty
                      "
                    >
                      <ng-container
                        *ngIf="
                          reqFC['userRequestCompany'].value === '';
                          else fnInvalid
                        "
                      >
                        Company name is required.
                      </ng-container>

                      <ng-template #fnInvalid>
                        <span *ngIf="!reqFC['userRequestCompany'].valid">
                          No unwanted space, numeric and special characters
                          allowed.
                        </span>
                      </ng-template>
                    </div>
                  </div>
                  <!-- End Request Access company error -->
                  <!-- END Request Access Company Form Group -->

                  <!-- Request Access Submit Button -->
                  <button
                    type="submit"
                    class="btn btn-lg btn-block btn-gradient my-2"
                    tabindex="0"
                    [ngStyle]="{
                      cursor:
                        requestForm.status !== 'VALID'
                          ? 'not-allowed'
                          : 'pointer'
                    }"
                    [disabled]="requestForm.invalid"
                  >
                    Request For Access
                  </button>
                  <!-- END Request Access Submit Button -->

                  <!-- Go back to Log in Button -->
                  <a
                    class="text-primary back_to_login"
                    (click)="GoToLoginFields()"
                    tabindex="0"
                  >
                    <i class="bi bi-chevron-left fs-6"></i> Back to Login
                  </a>
                  <!-- END Go back to Log in Button -->
                </div>
              </form>
              <!-- End Request Access Form Start -->
            </ng-template>
            <!-- End SHOW REQUEST ACCESS FORM -->

            <!-- SHOW Request Access mail sent success -->
            <ng-template #showRAMailSent>
              <!-- Forgot Form Start -->
              <div class="forgot-password-fields" id="cst_forgotpwd_form">
                <div class="login-fields text-center">
                  <img
                    src="assets/img/success.svg"
                    alt="Request For Access Success"
                  />
                  <h2 id="frgt_t" class="mb-1 pt-1">Your Request Sent</h2>
                  <p class="text-light">
                    Thanks for showing interest. Our team will get back to you.
                  </p>
                </div>
              </div>
              <!-- End Forgot Form Start -->
            </ng-template>
            <!-- End SHOW Request Access mail sent success -->
          </div>
        </div>
        <p class="small text-center footer_copyrights">Copyright &#169; SMARTPING AI PRIVATE LIMITED. All Rights Reserved | <a href="https://smartping.ai/privacy-policy" target="_blank" class="text-primary">Privacy Policy</a></p>
      </div>
      <!-- END Login Right Side -->
    </div>
    <!-- End Row -->
  </div>
  <!-- End Content -->
</div>
<!-- ========== END MAIN CONTENT ========== -->
