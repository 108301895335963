import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../services/CustomValidators';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import * as authactions from 'src/app/login/store/login.actions';
import {
  selectCommonDataDtls,
  selectProfilesDtlsData,
} from '../store/common.selectors';
import { AlertService } from '../alert/alert.service';
import { selectAuthUserData } from './store/login.selectors';
import { SharedService } from '../services/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthData } from '../appdata/auth.model';
import { WhitelabelingData } from '../appdata/initialwlb.data';
import * as commonactions from '../store/common.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  loginForm!: FormGroup;
  forgotForm!: FormGroup;
  requestForm!: FormGroup;
  showPassword = false;
  showAccessDenied = false;
  showLoginLoader: boolean = false;
  whiteLabeledData: any = WhitelabelingData;
  displayTemplate!: TemplateRef<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('showLogin') private showLogin!: TemplateRef<any>;
  @ViewChild('showForgotPswd') private showForgotPswd!: TemplateRef<any>;
  @ViewChild('showRequestAccess') private showRequestAccess!: TemplateRef<any>;
  @ViewChild('showResetPwdLink') private showResetPwdLink!: TemplateRef<any>;
  @ViewChild('showRAMailSent') private showRAMailSent!: TemplateRef<any>;
  loggedInUserDetails: any;
  selectedBA: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private cv: CustomValidators,
    private alertMsg: AlertService,
    private cookie: CookieService,
    private shareservice: SharedService,
    private readonly store: Store
  ) {}

  ngOnInit(): void {
    this.initializeLoginForm();
    this.initializeForgotForm();
    this.initializeRequestForm();
    this.checkRememberedUser();
    this.subscribeToWblStateData();
    this.subscribeToAuthUserData();
    this.subscribeToCommonAuthStateData();
    this.initiateAutoLogin();
  }

  initializeLoginForm() {
    this.loginForm = this.fb.group({
      useremail: [
        null,
        [Validators.required, Validators.pattern(this.cv.emailRegex)],
      ],
      cst_login_password: [null, Validators.required],
      keepSignedIn: [false],
    });
  }

  initializeForgotForm() {
    this.forgotForm = this.fb.group({
      userEmail: [
        null,
        [Validators.required, Validators.pattern(this.cv.emailRegex)],
      ],
    });
  }

  initializeRequestForm() {
    this.requestForm = this.fb.group({
      userRequestFirstname: [
        null,
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
      userRequestLastname: [
        null,
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
      userRequestEmail: [
        null,
        [Validators.required, Validators.pattern(this.cv.emailRegex)],
      ],
      userRequestPhone: [null, [Validators.required]],
      userRequestCompany: [
        null,
        [Validators.required, Validators.pattern(this.cv.nameRegex)],
      ],
    });
  }

  checkRememberedUser() {
    const uname = this.cookie.get('waba_usm');
    const pwd = this.cookie.get('waba_pswd');
    if (uname && pwd) {
      this.loginForm.patchValue({
        useremail: uname,
        cst_login_password: pwd,
        keepSignedIn: true,
      });
    }
  }
  subscribeToCommonAuthStateData() {
    this.store
      .select(selectProfilesDtlsData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loggedInUserDetails) {
          this.loggedInUserDetails = res.loggedInUserDetails;
        }
        if (res.sel_ba) {
          this.selectedBA = res.sel_ba;
        }
      });
  }
  subscribeToWblStateData() {
    this.store
      .select(selectCommonDataDtls)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.wlbres) {
          this.whiteLabeledData = res.wlbres.wlb_settings
            ? res.wlbres.wlb_settings
            : res.wlbres;
          if (typeof this.whiteLabeledData === 'string') {
            this.whiteLabeledData = JSON.parse(this.whiteLabeledData);
          }
        } else if (res.error) {
          const dispErr = res.error.error.message;
          this.handleLoginError(dispErr);
        }
      });
  }

  subscribeToAuthUserData() {
    this.store
      .select(selectAuthUserData)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res.loginres) {
          this.handleSuccessfulLogin(res.loginres);
        } else if (res.forgotPswdRes && res.forgotPswdRes.status_code === 200) {
          this.handleForgotPswdLink();
        } else if (res.error) {
          this.showLoginLoader = false;
          if (res.error.error?.message) {
            const dispErr = res.error.error.message;
            this.handleLoginError(dispErr);
          } else if (res.error.status_code === 500) {
            this.handleLoginError(res.error.message);
          }
        }
      });
  }

  handleForgotPswdLink() {
    this.displayTemplate = this.showResetPwdLink;
    this.forgotForm.reset();
  }

  handleSuccessfulLogin(loginres: AuthData) {
    const profileId = loginres.data.profile.id;
    if (this.loginForm.value.keepSignedIn) {
      this.cookie.set('waba_usm', this.loginForm.value.useremail);
      this.cookie.set('waba_pswd', this.loginForm.value.cst_login_password);
    }
    if (
      loginres.data.profile.acl &&
      loginres.data.account.account_type === 'CUSTOMER'
    ) {
      if (loginres.data.account.ocpm_channel === 'WABA') {
        const channeldata =
          loginres.data.profile.acl.abac.abac_policies.waba_policies.find(
            (t: any) => t.is_default === true
          );
        if (channeldata) {
          const selba = loginres.data.account.licenses.license_data.find(
            (t: any) =>
              t.channel_credentials.waba_number === channeldata.waba_number
          );
          if (selba) {
            this.shareservice.selectedBA$.next(selba);
            this.store.dispatch(
              commonactions.selectedBspDetails({ data: selba })
            );
            //localStorage.setItem('sel_ba', JSON.stringify(selba));
            this.router.navigate([
              this.shareservice.getRoutingUrl(),
              profileId,
              'dashboard',
            ]);
          }
        } else {
          this.router.navigate([
            this.shareservice.getRoutingUrl(),
            profileId,
            'product',
          ]);
        }
      }
    } else {
      this.router.navigate(['page-not-found']);
    }
  }

  handleLoginError(msg: string) {
    this.alertMsg.alertDanger('Error', msg);
  }

  initiateAutoLogin() {
    const authData = this.loggedInUserDetails
      ? JSON.parse(JSON.stringify(this.loggedInUserDetails))
      : null;
    //JSON.parse(localStorage.getItem('wabaauthdata'));

    if (
      authData?.token &&
      authData.account.account_type === 'CUSTOMER' &&
      authData.account.ocpm_channel === 'WABA'
    ) {
      const sel_ba = JSON.parse(JSON.stringify(this.selectedBA));
      //JSON.parse(localStorage.getItem('sel_ba'));
      const customerId = authData.profile.id;
      const routePrefix = 'customer/' + customerId + '/';

      const destinationRoute = sel_ba ? 'dashboard' : 'product';

      this.router.navigate([routePrefix + destinationRoute]);
    }
  }

  ngAfterViewInit() {
    this.displayTemplate = this.showLogin;
    this.cd.detectChanges();
  }

  get loginFC() {
    return this.loginForm.controls;
  }
  get fgtFC() {
    return this.forgotForm.controls;
  }
  get reqFC() {
    return this.requestForm.controls;
  }

  forgotPassword() {
    this.displayTemplate = this.showForgotPswd;
  }
  resetPwdLink() {
    this.displayTemplate = this.showResetPwdLink;
  }
  GoToLoginFields() {
    this.displayTemplate = this.showLogin;
    this.forgotForm.reset();
  }
  requestAccess() {
    this.displayTemplate = this.showRequestAccess;
  }

  requestAccessEmail(form: FormGroup) {
    this.requestAccessMailSuccess();
    form.reset();
  }

  requestAccessMailSuccess() {
    this.displayTemplate = this.showRAMailSent;
  }

  login(form: FormGroup) {
    if (form.invalid) {
      return;
    }

    const auth_payload = {
      email: form.value.useremail,
      password: form.value.cst_login_password,
    };
    this.showLoginLoader = true;
    this.store.dispatch(
      authactions.authenticateUser({ payload: auth_payload })
    );
  }

  forgotPswd(form: FormGroup) {
    if (!form.valid) {
      return;
    }

    const pswd_payload = {
      email: form.value.userEmail,
    };

    this.store.dispatch(authactions.forgotPassword({ payload: pswd_payload }));
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.showLoginLoader = false;
  }
}
