<app-alert></app-alert>

<!-- Main Content Container -->
<div class="content container-fluid p-0 vh-100">
  <ng-container [ngTemplateOutlet]="displayTemplate"></ng-container>
</div>
<!-- END Main Content Container -->

<!-- Request for Support -->
<ng-template #support>
  <div id="headerMain">
    <header id="header" class="navbar-fixed">
      <div
        class="navbar navbar-expand-lg navbar-flush navbar-container navbar-bordered py-2"
      >
        <div class="navbar-nav-wrap">
          <!-- Brand Logo -->
          <div class="navbar-brand-wrapper col-sm-2">
            <!-- Logo -->
            <a class="navbar-brand" aria-label="Brand Logo">
              <img
                class="navbar-brand-logo"
                [src]="whiteLabeledData?.logo"
                alt="Logo"
                width="200px"
              />
            </a>
            <!-- End Logo -->
          </div>
          <!-- Brand Logo -->

          <!-- Secondary Content -->
          <div
            class="navbar-nav-wrap-content-right col-lg-auto col-sm-10"
            *ngIf="metadata"
          >
            <!-- Navbar -->
            <ul class="navbar-nav align-items-center flex-row">
              <!-- Account -->
              <li class="nav-item">
                <div class="hs-unfold">
                  <a
                    class="navbar-dropdown-account-wrapper"
                    [matMenuTriggerFor]="profileSettingsMenu"
                  >
                    <div
                      class="d-flex align-items-center justify-content-center gap-2"
                    >
                      <div class="avatar avatar-sm avatar-circle">
                        <ng-container
                          *ngIf="profile?.profile_image_url; else defaultImg"
                        >
                          <img
                            class="avatar-img"
                            src="{{ profile?.profile_image_url }}"
                            alt="{{ profile?.first_name }}{{
                              profile?.last_name
                            }}"
                          />
                        </ng-container>
                        <ng-template #defaultImg>
                          <img
                            class="avatar-img"
                            src="assets/img/customer/default_avatar.jpg"
                            alt="User Default Avatar"
                          />
                        </ng-template>
                      </div>
                      <span class="c_name"
                        >{{ profile?.first_name | titlecase }}
                        {{ profile?.last_name | titlecase }}</span
                      >
                    </div>
                  </a>
                  <!-- User Account Dropdown -->
                  <mat-menu #profileSettingsMenu="matMenu">
                    <div
                      id="accountNavbarDropdown"
                      class="navbar-dropdown-account"
                    >
                      <div class="dropdown-item-text">
                        <div class="profile_details d-flex">
                          <div class="avatar avatar-circle">
                            <ng-container
                              *ngIf="
                                profile?.profile_image_url;
                                else defaultImg
                              "
                            >
                              <img
                                class="avatar-img"
                                src="{{ profile?.profile_image_url }}"
                                alt="{{ profile?.first_name }} {{
                                  profile?.last_name
                                }}"
                              />
                            </ng-container>
                            <ng-template #defaultImg>
                              <img
                                class="avatar-img"
                                src="assets/img/customer/default_avatar.jpg"
                                alt="Default Avatar"
                              />
                            </ng-template>
                            <span
                              class="p-1 border border-light rounded-circle text-success status_dot"
                            ></span>
                          </div>
                          <div class="flex-grow-1 ms-2">
                            <h5 class="mb-0 text-capitalize">
                              {{ profile?.first_name | titlecase }}
                              {{ profile?.last_name | titlecase }}
                            </h5>
                            <small class="card-text">
                              {{ profile?.email }}
                            </small>
                          </div>
                        </div>
                      </div>

                      <div class="dropdown-divider"></div>
                      <!-- Header Signout Option -->
                      <button
                        mat-menu-item
                        (click)="openVerticallyCentered(logoutModal)"
                      >
                        <em class="bi-box-arrow-right"></em> Sign out
                      </button>
                      <!-- END Header Signout Option -->
                    </div>
                  </mat-menu>
                  <!-- End User Account Dropdown -->
                </div>
              </li>
              <!-- End Account -->
            </ul>
          </div>
        </div>
      </div>
    </header>
  </div>

  <div class="card card-vh-100 d-flex align-items-center">
    <div class="success_screen w-80">
      <img
        src="assets/img/support.svg"
        alt="Request Support"
        class="success_img w-20"
      />

      <h3>
        <span *ngIf="!metadata">
          Looks like there is no WABA Number associated with this service
          account.
        </span>
        <span *ngIf="metadata">
          The WABA Number associated with this service account is still in the
          process of being provisioned.
        </span>
        <div>Please retry login with other service account from Smartvault</div>
      </h3>

      <div class="support_sec">
        <p>(or)</p>
        <mat-divider class="my-2"></mat-divider>
        <h4>Reachout to admin using the below details.</h4>
        <p>
          Support Email :
          <span class="h3 text-primary"
            ><a href="mailto:support.waba@smartping.ai"
              >support.waba&#64;smartping.ai</a
            ></span
          >
        </p>
        <p>
          Support Number :
          <span class="h3 text-primary"
            ><a href="tel:1800-911-1111">1800-911-1111</a></span
          >
        </p>
      </div>
    </div>
  </div>
</ng-template>
<!-- End Request for Support -->

<!-- Logout Modal Box -->
<ng-template #logoutModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Log out</h4>
  </div>
  <div class="modal-body">
    <div class="text-center">
      <div class="model_icon">
        <em class="bi-trash text-danger"></em>
      </div>
      <h4>Are you sure you wish to Log out</h4>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" (click)="d('Cross click')">
      Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="signout()">
      Confirm
    </button>
  </div>
</ng-template>
<!-- End Logout Modal Box -->
