//storage.metareducer.ts
//Code

import { ActionReducer, INIT, UPDATE, Action } from '@ngrx/store';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

// Key to access local storage and secret key for encryption
const localStorageKey = 'appState';
const secretKey = 'ocmp_project'; // Replace with your own secret key

// Function to encrypt data
function encrypt(data: any, key: string): string {
  return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}

// Function to decrypt data
function decrypt(data: string, key: string): any {
  const bytes = CryptoJS.AES.decrypt(data, key);
  const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedData);
}

function isTokenExpired(token: string): boolean {
  if (!token) return true;
  const payload = JSON.parse(atob(token.split('.')[1]));
  const exp = payload.exp;
  const currentTime = Math.floor(new Date().getTime() / 1000);
  return exp < currentTime;
}

// Meta-reducer to sync state to encrypted local storage
export function localStorageMetaReducer<S, A extends Action = Action>(
  reducer: ActionReducer<S, A>
): ActionReducer<S, A> {
  return (state, action) => {
    //console.log(action);

    // First initialize or update state with local storage data
    if (action.type === INIT || action.type === UPDATE) {
      const storedState = environment.sso
        ? sessionStorage.getItem(localStorageKey)
        : localStorage.getItem(localStorageKey);
      //console.log(storedState);

      if (storedState) {
        try {
          const decryptedState = decrypt(storedState, secretKey);
          let token;
          // console.log(decryptedState);

          if (!environment.sso) {
            token = decryptedState?.loggedInUserDetails?.token;
          } else {
            token = decryptedState?.loggedInSsoUserDetails?.token;
          }
          if (isTokenExpired(token)) {
            environment.sso
              ? sessionStorage.removeItem(localStorageKey)
              : localStorage.removeItem(localStorageKey);
            return undefined; // Reset the state
          }
          return decryptedState;
        } catch {
          environment.sso
            ? sessionStorage.removeItem(localStorageKey)
            : localStorage.removeItem(localStorageKey);
        }
      }
    }

    // Delegate the action to the reducer to get the next state
    const nextState = reducer(state, action);

    // Save the next state to local storage
    const storedState = environment.sso
      ? sessionStorage.getItem(localStorageKey)
      : localStorage.getItem(localStorageKey);
    if (
      action.type === '[Login] Authenticate User Success' ||
      action.type === '[Sso] Start Sso Actions' ||
      storedState
    ) {
      const encryptedState = encrypt(nextState, secretKey);
      environment.sso
        ? sessionStorage.setItem(localStorageKey, encryptedState)
        : localStorage.setItem(localStorageKey, encryptedState);
    }

    return nextState;
  };
}
