import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { map, exhaustMap, catchError, tap } from 'rxjs/operators';
import * as authactions from './login.actions';
import * as commonactions from '../../store/common.actions';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AuthData } from 'src/app/appdata/auth.model';
import { AlertService } from 'src/app/alert/alert.service';

@Injectable()
export class LoginEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private alertMsg: AlertService,
    private loginservice: AuthenticationService
  ) {}

  authenticateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authactions.authenticateUser),
      exhaustMap((action) =>
        this.loginservice.login(action.payload).pipe(
          map((response: AuthData) => {
            if (response.status_code === 404 || response.status_code === 401) {
              return authactions.authenticateUserFailure({
                error: response.message,
              });
              // this.alertMsg.alertDanger('Error', );
            } else if (response.status_code === 200) {
              // if (response.data.token) {
              //   // localStorage.setItem('waba_token', response.data.token);
              //   // localStorage.setItem(
              //   //   'wabaauthdata',
              //   //   JSON.stringify(response.data)
              //   // );
              // }
              return authactions.authenticateUserSuccess({
                data: response,
              });
            } else if (response.status_code === 500) {
              return authactions.authenticateUserFailure({
                error: response.message,
              });
            }
          }),
          catchError((error: any) =>
            of(authactions.authenticateUserFailure({ error: error }))
          )
        )
      )
    );
  });

  forgotPswd$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authactions.forgotPassword),
      exhaustMap((action) =>
        this.loginservice.forgotpswd(action.payload).pipe(
          map((response: any) => {
            return authactions.forgotPasswordSuccess({
              data: response,
            });
          }),
          catchError((error: any) =>
            of(authactions.forgotPasswordFailure({ error: error }))
          )
        )
      )
    );
  });

  tokendetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authactions.authenticateUserSuccess),
      map((action) => {
        return commonactions.tokendetails({ data: action.data.data.token });
      })
    );
  });

  successLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authactions.authenticateUserSuccess),
      map((action) => {
        return commonactions.userdetails({
          data: action.data.data,
        });
      })
    );
  });

  logoutUser$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authactions.logoutUser),
        tap(() => {
          this.loginservice.logout();
          this.router.navigate(['login']);
        })
      );
    },
    { dispatch: false }
  );
}
